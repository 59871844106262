import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

// Import Controllers
import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

import "../utils/i18n"
import "../utils/trix"
import "../utils/turbo-mount"

// Start the thing
window.Stimulus = Application.start()
const controllers = import.meta.glob('../controllers/**/*_controller.{js,jsx}', { eager: true })
registerControllers(window.Stimulus, controllers)

import RailsNestedForm from '@stimulus-components/rails-nested-form'
window.Stimulus.register('nested-form', RailsNestedForm)

import TextareaAutogrow from 'stimulus-textarea-autogrow'
window.Stimulus.register('textarea-autogrow', TextareaAutogrow)

import { Tabs } from "tailwindcss-stimulus-components"
window.Stimulus.register('tabs', Tabs)
